@import "assets/scss/style.scss";
// @import "assets/scss/_function";
:root {
  --font-size: 16px;
}
body {
  font-family: $family-regular;
  font-size: theme-fonts(font-16);
  line-height: 1.5;
}
*:focus,
.btn-check:focus + .btn,
.btn:focus {
  outline: none;
  box-shadow: none;
}

h1,
.h1 {
  font-size: theme-fonts(font-36);
  @include media-breakpoint-up(xl) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
  line-height: 1.5;
  font-weight: theme-fonts(font-500);
}
h2,
.h2 {
  font-size: theme-fonts(font-32);
  @include media-breakpoint-up(xl) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
  line-height: 1.5;
  font-weight: theme-fonts(font-500);
}
h3,
.h3 {
  font-size: theme-fonts(font-24);
  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  line-height: 1.5;
  font-weight: theme-fonts(font-400);
}
h4,
.h4 {
  font-size: theme-fonts(font-20);
  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  line-height: 1.5;
  font-weight: theme-fonts(font-500);
}
h5,
.h5 {
  font-size: theme-fonts(font-18);
  @include media-breakpoint-up(xl) {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  line-height: 1.5;
  font-weight: theme-fonts(font-400);
}
h6,
.h6 {
  font-size: theme-fonts(font-16);
  @include media-breakpoint-up(xl) {
    font-size: 1rem;
    line-height: 2rem;
  }
  line-height: 1.5;
  font-weight: theme-fonts(font-400);
}
.bg-blue {
  background-color: theme-colors(primary);
}

.btn-blue {
  @include button(
    theme-colors(primary),
    theme-colors(white),
    theme-colors(primary)
  );
  &:hover,
  &:active {
    @include buttonHover(
      transparent,
      theme-colors(primary),
      theme-colors(primary)
    );
  }
}

.btn-white {
  @include button(transparent, theme-colors(primary), theme-colors(primary));
  &:hover,
  &:active {
    @include buttonHover(
      theme-colors(primary),
      theme-colors(white),
      theme-colors(primary)
    );
  }
}
.learncourse-slider {
  @include media-breakpoint-down(lg) {
    a {
      &.sm-btn,
      &.sm-white-btn {
        display: block;
        margin: 0.5rem;
      }
    }
  }
}

.two-col-card-wrapper {
  @include media-breakpoint-down(lg) {
    a {
      &.sm-btn,
      &.sm-white-btn {
        margin: 0.5rem;
      }
    }
  }
}
a {
  @include media-breakpoint-down(lg) {
    &.sm-btn {
      &.text-success {
        @include button(
          transparent,
          theme-colors(success),
          theme-colors(success)
        );
        &:hover,
        &:active {
          @include buttonHover(
            theme-colors(success),
            theme-colors(white),
            theme-colors(success)
          );
          color: theme-colors(white) !important;
        }
      }
      &.text-primary {
        @include button(
          theme-colors(primary),
          theme-colors(white),
          theme-colors(primary)
        );
        &:hover,
        &:active {
          @include buttonHover(
            transparent,
            theme-colors(primary),
            theme-colors(primary)
          );
          color: theme-colors(primary) !important;
        }
        color: theme-colors(white) !important;
      }

      display: inline-block;
      text-align: center;
      @include media-breakpoint-down(md) {
        padding: 0.4rem 0.5rem;
      }
    }
    &.sm-white-btn {
      &.text-success {
        @include button(
          transparent,
          theme-colors(success),
          theme-colors(success)
        );
        &:hover,
        &:active {
          @include buttonHover(
            theme-colors(success),
            theme-colors(white),
            theme-colors(success)
          );
          color: theme-colors(white) !important;
        }
      }
      &.text-primary {
        @include button(
          transparent,
          theme-colors(primary),
          theme-colors(primary)
        );
        &:hover,
        &:active {
          @include buttonHover(
            theme-colors(primary),
            theme-colors(white),
            theme-colors(primary)
          );
          color: theme-colors(white) !important;
        }
      }

      display: inline-block;
      text-align: center;
      @include media-breakpoint-down(md) {
        padding: 0.35rem 0.5rem;
      }
    }
  }
}

.footer-lg {
  @include padding-top(1rem);
  @include padding-bottom(1rem);
}

.footer-sm {
  display: none;
  padding: 0rem 0.25rem;
  z-index: 11;
  left: 0px;
  @media only screen and (max-width: 1025px) {
    display: block;
  }
  a {
    display: inline-block;
    text-align: center;
    padding: 1rem 0.5rem;
    @include media-breakpoint-down(md) {
      padding: 0.625rem 0px;
    }
    img {
      height: 0.9375rem;
      width: auto;
      display: block;
      margin: 0 auto 5px;
    }
    span {
      font-size: theme-fonts(font-12);
      @media only screen and (max-width: 370px) {
        font-size: theme-fonts(font-10);
      }
      display: block;
    }
  }
}

.tech {
  .card {
    border: 1px solid theme-colors(border-color);
    border-radius: 5px;
    &.subscribe-card {
      background: #f0f0f0;
      border-color: #04a429;
    }
  }
}
.formloader {
  position: fixed;
  height: 99vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.css-b62m3t-container {
  width: 100% !important;
}
