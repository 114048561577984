.table-data thead th {
    position: sticky;
    top: 90px;
  
  }
  .main-dev{
    padding-top: 4rem !important;
  }
  .table-data thead{
    position: sticky;
    z-index: 9;
  }
  .table-data tbody th {
    position: sticky;
    left: -1px;
   
  }
  
  .table-data thead tr:nth-child(2) th{
    z-index: 1 !important;
  }
  
  .table-data tbody th:nth-child(2),  
  .table-data thead th:nth-child(2) {
    left: 30px;
    background-color: #ffffff ;
    z-index: 3;
    position: sticky;
  }
  .table-data thead th:nth-child(1) {
    background-color: #ffffff;
    left: 0;
    z-index: 3;    
  }
  .table-data thead{
      background-color: #19456b;
  }
  .table-data tbody th:first-child{
    background-color: #fff;
  }  
  div#react-select-11-listbox,div#react-select-5-listbox ,div#react-select-7-listbox, div#react-select-9-listbox{
    z-index: 99;
  }
   .input-option .css-b62m3t-container{
    position: relative;
    z-index: 99;
  }
  .table-data{
    margin-top: 104px !important;
    border-spacing: 0;
    border-collapse: unset !important;
  }
  .table-data tr td,.table-data tr th {
    border-bottom-width: 1px;
  }
  /* Heading odd even color
  1st #3996e7
  2nd #0089ff
  */

  .info-section .info-table  th,.info-section .info-table td,
  .info-section .info-table1 td,
  .info-section .info-table2 td,
  .info-section .info-table1 th,
  .info-section .info-table2 th {
    border:1px solid black;
    text-align: center;
    padding: 7px;
  }
  .info-section .info-table th,.info-section .info-table1 th , .info-section .info-table2 th{
    background-color: #19456b !important;
    color: #fff !important;
  }
  .info-section .info-table1,.info-section .info-table2{
    position: absolute;
    bottom: -80px;
    left: 0;
    text-align: center;
  }
  .info-section .info-table2{
    left: 200px;
  }
  .info-section .info-table{
   display: flex;
   position: absolute;
   bottom: -80px;
  }
  .info-section .download-btn{
    position: absolute;
    bottom: -50px;
    right: 140px;
  }
  tbody, td, tfoot, th, thead, tr {
    border-style: solid !important;
  }
  @media screen and (max-width:1600px) {
    .info-section .info-table,.info-section .info-table1,.info-section .info-table2, .info-section .download-btn {
      position: unset;
      bottom: unset;
    }
    
  }
  @media screen and (max-width:1441px) {
    .main-dev .input-option{
     flex-wrap: wrap !important;
     gap: 10px !important;
    }
    .overflow-scrolling{
      overflow-x: auto !important;
      scroll-behavior: smooth; 
    }
    .fixed-bottom{
      margin-bottom: unset !important;
    }  
  }
  @media screen and (max-width:1191px) {
    .input-option .input-box p{
      font-size: 14px;
    }
    .css-b62m3t-container {
      z-index: unset;
    }
  }
  @media screen and (max-width:600px) {
    .info-table{
      display: initial !important;
    }
    .info-table thead tr{  
      display: contents !important;
    }
    .info-table tbody, .info-table tbody tr{
      display: revert !important;
    }
  }
  
  
  /*  */
  
  .bg-success{
      background-color: #70AD47 !important;
  }
  .bg-primary{
      background-color: #A9D08E !important;
  }
  .bg-infos{
      background-color: #ffc107 !important;
  }
  .bg-warning{
      background-color: #0DCAF0 !important;
  }
  .bg-danger{
      background-color: #ef5347 !important;
  }
  .bg-gray-200{
    background-color: #E9ECEF;
  }
  