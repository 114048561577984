@import "../../assets/scss/style.scss";

.new-course
{
    .slick-slider
    {
        .slick-list
        {
            .slick-slide
            {
                padding: 0 0.625rem;
                height: 100%;
            }
        }
    }

    // .course-img img
    // {
    //     height: 100%;
    //     object-fit: cover;
    // }
    .card
    {
        &.subscribe-card{
            background: #f0f0f0;
            border-color: #04A429;
          }
        .card-body
        {
            display: flex;
            flex-direction: column;
            height: 100%;
            .card-text
            {
                &.card-small-content {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    // min-height: 63px;
                }
            }
            .course-progressbar
            {
                overflow: hidden;
                max-width: 36rem;
                width: 100%;
                height: 0.3125rem;
            }
            a
            {
                text-decoration: none;
                &:hover
                {
                    text-decoration: underline;
                    @include media-breakpoint-down(md)
                    {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}