@import "../../assets/scss/style.scss";
.courses {
  .home-banner {
    background-repeat: no-repeat;
    background-position: center right -13.75rem;
    background-size: cover;
    @media only screen and (min-width: 1920px) {
      background-position: center right 0;
    }
  }
}
