@import "../../assets/scss/style.scss";
.sidebar {
    max-height: 80vh;
    overflow-y: auto;
    @include media-breakpoint-down(xl) {
      max-height: 90vh;
    }
    .course-detail-accordion
    {
      .accordion-body
      {
        ul li 
        {
          padding-left: 0;
          &:before
          {
            content: none;
          }
          p
          {
            cursor: pointer;
            padding-left: 0;
            word-break: break-word;
            i
            {
              margin-left: -1.875rem;
              margin-top: 0.1875rem;
              position: absolute;
            }
          }
        }
      }
    }
    &.sidebar-module {
      .accordion-content .accordion-item .accordion-button:not(.collapsed)::after {
        //content: none;
      }
      .accordion-button:not(.collapsed), .accordion-content .accordion-item .accordion-button:not(.collapsed):focus {
        box-shadow: none;
      }
      a{
        margin-bottom: 0px;
      }
    }
}
.play{
    content: url('../../assets/images/gray-circle.svg');;
}
.actives{
  content: url('../../assets/images/blue-circle.svg');;
  // filter: ;
}
.notplay{
  content: url('../../assets/images/confirm.svg');
}




::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: none; 
    border-radius: 10px;
    background: #E5E5E5;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #999999; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #999999; 
  }