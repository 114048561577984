.container {
  .formloader {
    position: fixed;
    background: #00000065;
    height: 99vh;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    img {
      filter: brightness(100);
    }
    .confirmbox {
      background: #ffffff;
      padding: 1rem;
      border-radius: 0.2rem;
    }
  }
  .selheader {
    position: absolute;
    top: 1.2rem;
    left: 1.7rem;
    right: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .col-auto {
      .count {
        display: flex;
        align-items: center;
        color: #0d6efd;
      }
      button {
        display: flex;
        align-items: center;
        box-shadow: none !important;
      }
    }
  }
  .form {
    min-height: 90vh;
    .heading {
      font-weight: 600;
    }
    .listcourses {
      .citem {
        box-shadow: 0 0 7px #554a4a50;
        cursor: pointer;
        &:hover {
          background: #e3ffff80 !important;
        }
      }
    }
    form {
      font-size: 0.9rem;
      .form-control {
        font-size: 0.9rem;
      }
      label {
        color: theme-colors(color-dback);
      }
      .invalidate {
        background: rgba(220, 53, 69, 0.9);
        z-index: 1;
      }
      .btn-submit {
        background: theme-colors(green);
        color: #ffffff;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .listppts {
        .card {
          overflow: hidden;
          position: relative;
          box-shadow: 0 0 7px #554a4a50;
          &:hover {
            box-shadow: 0 0 10px #554a4a75;
            .checkheader {
              background-image: linear-gradient(#000000, #ffffff00);
            }
          }
          .checkheader {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0.5rem;
            padding-bottom: 1rem;
            width: 100%;
            z-index: 1;
            input[type="checkbox"] {
              height: 1.6rem;
              width: 1.6rem;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .removecourse {
      button {
        &:hover {
          background: theme-colors(light-background) !important;
          color: theme-colors(color-dback) !important;
          border: 1px solid theme-colors(green);
        }
        svg {
          &:hover {
            color: theme-colors(success);
          }
        }
      }
    }
    .clr1 {
      background: #ff6c6ce1;
    }
    .clr2 {
      background: #b6b618e5;
    }
    .clr3 {
      background: #7eff7ee3;
    }
    .clr4 {
      background: #5ce7e7e0;
    }
    .clr5 {
      background: #8080ffe0;
    }
    .clr6 {
      background: #ff7efff6;
    }
    .clr7 {
      background: #ffbb7bd0;
    }
  }
}
@media only screen and (min-width: 1700px) {
  .scrollbutton {
    position: fixed;
    right: 60px;
    bottom: 30px;
    height: 50px;
    width: 50px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #000000;
    background-color: #ffffff;
    padding: 8px;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
  }
}
@media only screen and (max-width: 1700px) {
  .scrollbutton {
    position: fixed;
    right: 10px;
    bottom: 20px;
    height: 40px;
    width: 40px;
    font-size: 3rem;
    z-index: 1;
    padding: 8px;
    cursor: pointer;
    color: #000000;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
  }
}
@media only screen and (max-width: 600px) {
  .scrollbutton {
    position: fixed;
    right: 2px;
    bottom: 2px;
    font-weight: lighter;
    padding: 8px;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
    font-size: 10px;
    z-index: 1;
    cursor: pointer;
    color: #000000;
    background-color: #ffffff;
  }
}

.ck-editor__editable {
  min-height: 300px;
}

.image img {
  width: 1100px;
  height: auto;
}
p img {
  width: 1100px;
  height: auto;
}
.table-responsive{
  max-height: 450px !important;
}
.thead-custom {
  position: sticky;
  top: -1px;
  height: 40px;
  background-color: greenyellow;
}
