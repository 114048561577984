@import "../../assets/scss/style.scss";
.subscribe-slider
{
    @include media-breakpoint-down(md) {
        .slick-track
        {
            display: flex !important;
        }

        .slick-slide
        {
            height: inherit !important; 
            div{
                height: 100%;
            }
            .card-body {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
    .slick-slider
    {
        ul.slick-dots
        {
            li
            {
                width: 0.625rem;
                height: 0.625rem;
                margin: 0 0.3125rem;
                button
                {
                    width: 0.625rem;
                    height: 0.625rem;
                    padding: 0;
                    background: transparent;
                    &::before
                    {
                        width: 0.625rem;
                        height: 0.625rem;
                        color: theme-colors(dark-gray);
                        content: "";
                        border: 1px solid theme-colors(dark-gray);
                        border-radius: 50%;
                        background: transparent;
                        opacity: 1;
                    }

                }
                &.slick-active
                {
                    button::before
                    {
                        background-color: theme-colors(dark-gray);
                    }
                }
            }
        }
        .slick-list
        {
            margin: 0 -1.5rem;
            @include media-breakpoint-down(xl)
            {
                margin: 0 -0.9375rem;
                margin-bottom: 1rem;
            }
            @include media-breakpoint-down(lg)
            {
                margin: 0 -0.625rem;
                margin-bottom: 1.25rem;
            }
            .slick-track
            {
                margin-left: 0;
            }
            .subscribe-slide {
                padding: 0 0.75rem;
            }
        }
    }
    .course-img
    {
        position: relative;
        img{
            object-fit: contain;
            width: 75px;
            padding: 0.75rem;
            margin: 0 auto;
        }
        &:after {
            content: "";
            width: 1px;
            height: 45%;
            background: #ddd;
            position: absolute;
            z-index: 11;
            right:0px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
}
