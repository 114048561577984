@mixin rounded($border) {
	-webkit-border-radius: $border;
	-moz-border-radius: $border;
	border-radius: $border;
}
@mixin button($bg-color, $fg-color, $border-color: '') {
    background-color: $bg-color;
    border: 1px solid $border-color;
    color: $fg-color;
    // font-size: theme-fonts(font-16);
    line-height: 1.375rem;
    padding: 0.625rem 1.375rem;
    transition: 0.3s all;
    min-width: 8.75rem;
	@include rounded($border-radius);
    @include media-breakpoint-down(lg) { 
        padding: 0.45rem 1rem;
        min-width: 7.75rem;
    }
}
@mixin buttonHover($hover-bg: '', $hover-fg: '', $border-color: '') {
    background-color: $hover-bg;
    color: $hover-fg;
    border-color: $border-color;
}
@function theme-colors($key) {
    @return map-get($theme-colors, $key);
}
@function theme-fonts($key) {
    @return map-get($theme-fonts, $key);
}

@function theme-weight($key) {
    @return map-get($theme-font-weight, $key);
}

