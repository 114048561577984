@import "../../assets/scss/style.scss";
$theme-color: #005ba0;
.cursor-pointer {
  cursor: pointer;
}
.calendar-block {
  .fc-daygrid-block-event {
    .fc-event-title {
      display: block;
      font-size: 12px !important;
      padding: 3px 5px;
      white-space: normal;
    }
  } 
}
.bl-5 {
  border-left: 5px solid $theme-color !important;
}
.fc {
  font-size: 12px;
  .fc-button-primary:not(:disabled):active:focus, 
  .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }
  a {text-decoration: none;}
  .fc-daygrid-more-link {color: $theme-color;}
  .fc-theme-standard .fc-popover-header {
    background: rgb(0 91 160);
    color: #fff;
  }
  .fc-daygrid-body-balanced .fc-daygrid-day-events {
    bottom: 0;
  }
  .fc-timegrid-axis-cushion, .fc-timegrid-slot-label-cushion {
    padding: 0 8px;
  }
  .fc-toolbar {
    display: block;
    margin: 0 auto;
    text-align: center;
    &.fc-header-toolbar {
      font-size: 14px;
      margin-bottom: 20px;
      > * {
        margin: 10px 0;
      }
    }
    .fc-button-primary {
      background-color: transparent;
      border-color: $theme-color;
      color: $theme-color;
      &:hover {
        background-color: $theme-color;
        border-color: $theme-color;
        color: #ffffff;
      }
      &:focus {
        box-shadow: none;
      }
      &:disabled {
        background-color: transparent;
        border-color: $theme-color;
        color: $theme-color;
      }
      &:not(:disabled):active, 
      &:not(:disabled).fc-button-active {
        background-color: $theme-color;
        border-color: $theme-color;
        color: #ffffff;
        &:hover {
          background-color: transparent;
          border-color: $theme-color;
          color: $theme-color;
        }
      }
    }
  }
  .fc-toolbar-title {
    font-size: 20px;
  }
}

/*Scroll bar nav*/
::-webkit-scrollbar {
  width: 2px;
}

@include media-breakpoint-up(md) {
  .fc {
    font-size: 14px;
    .fc-toolbar {
      display: flex;
      margin: unset;
    }
    .fc-toolbar-title {
      font-size: 22px;
    }
    .fc-timegrid-axis-cushion {
      max-width: 90px;
      width: 90px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .fc {
    .fc-toolbar {
      display: block;
      margin: 0 auto;
    }
    .fc-toolbar-title {
      font-size: 25px;
    }
  }
}
@include media-breakpoint-up(xl) {
  .fc {
    .fc-daygrid-body-balanced .fc-daygrid-day-events {
      bottom: unset;
    }
    .fc-toolbar {
      display: flex;
      margin: unset;
    }
    .fc-toolbar-title {
      font-size: 28px;
    }
  }
}
