@import "../../assets/scss/style.scss";
.logo-container {
    img {
        object-fit: contain;
        transition: all ease 0.5s;
        @include media-breakpoint-down(md) {
            width: 2.8125rem; 
        }
    }
    // .slick-slide
    // {
    //     padding: 1.25rem 0;
    // }
}
.logo-slider
{
        align-self: flex-end;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
}
@keyframes bounce {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    100% { transform: translateY(0); }
  }
  .bounce:hover {
    animation-name: bounce;
    animation-timing-function: ease;
    @include media-breakpoint-down(xl) {
        animation-name: auto;
    }
}