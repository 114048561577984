@import "../../assets/scss/style.scss";


 
  .course-img {
    background: theme-colors(light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    @include media-breakpoint-down(md) {
      height: 10rem;
    }
    img {
      object-fit: contain;
      width: 94px;
      padding: 0.75rem;
    }
  }
  .card {
    .btn-wrapper {
      border-top: 1px solid theme-colors(border-color);
      @include media-breakpoint-down(md)
      {
        border-top: 0;
        margin: 0 -0.5rem;
      }
    }
    .card-body
    {
      .card-title
      {
        @include media-breakpoint-down(md)
        {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
.two-col-card-wrapper
{
  .card {
    transition: 0.5s all;
    &:hover{
      box-shadow: 0px 0px 15px #00000029;
    }
  }
}
  