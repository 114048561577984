@import "../../assets/scss/style.scss";
.learningTab {
    transition: all ease 2s;
    width: 100%;
    background-color: theme-colors(page-bg);
    z-index: 11;
    .nav-link.active
    {
        background-color: theme-colors(page-bg);
    }
}
.result_table
{
    >:not(:first-child)
    {
        border-top: 2px solid theme-colors(white);
    }
    border: 1px solid theme-colors(light);
    thead{
        background-color: #005ba0;
        color: #ffffff;
        vertical-align: middle;
        tr th{
            font-weight: 500;
            padding: 1.25rem 0.625rem;
            font-size: 0.9375rem;
            line-height: 1.375rem;
            text-align:center;
            @include media-breakpoint-down(lg)
            {
                font-size: 12px;
                padding: 0.5rem 0.625rem;
                line-height: 18px;
                font-weight: 400;
            }
            &:first-child {
                padding: 1.25rem 1.5625rem;
            }
        }   
    }
    tbody 
    {
        
        tr:nth-of-type(odd) td
        {
            background-color: rgba(222, 244, 255, 0.3);
            --bs-table-accent-bg: theme-colors(white);
        }
        td
        {
            font-size: 0.875rem;
            vertical-align: middle;
            text-align: center;
            border-left-width: 1px;
            &:first-child
            {
                padding:0.75rem 1.5625rem;
            }
        }
    }
    
}
@include media-breakpoint-down(xl)
{
    .result_table{
        display: block;
    overflow-x: auto;
    overflow-y: hidden;
    }
}