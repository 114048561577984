
.dashboard {
  .leftbar {
    background:  #19456b;

    .accordion {
      .accordion-item {
        color: #ffffff;
        background: transparent;
        border: none;
        .accordion-header {
          button {
            background: transparent;
            color: #ffffff;
            &::after {
              filter: brightness(20);
            }
          }
        }
        .accordion-collapse {
          .accordion-body {
            button {
              &:hover {
                background: theme-colors(green);
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
  .rightbar {
    // remove it
    position: relative;
  }
}
