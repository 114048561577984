@each $name, $color in $theme-colors {
    .text-#{$name} {
        color: $color !important;
    }
    .bg-#{$name} {
        background-color: $color !important;
    }
}

@each $name, $size in $theme-fonts {
    .#{$name} {
        font-size: $size;
    }
}

@each $name, $weight in $theme-font-weight {
    .#{$name} {
        font-weight: $weight;
    }
}

section
{
    @include padding-top(3.75rem);
    @include padding-bottom(3.75rem);

    @include media-breakpoint-down(xl) {
        padding-top: 3rem;
        padding-bottom: 3rem;
        &.embed-responsive {
            padding-top: 0;
        }
    }
    @include media-breakpoint-down(lg) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.pl-110 {
    @include padding-left(6.875rem);
    @include media-breakpoint-down(lg) {
        @include padding-left(2.875rem);
    }
    @include media-breakpoint-down(sm) {
        padding-left: 0rem;
    }
}

.pt-80 {
    @include padding-top(5rem); 
}
.px-12px {
    @include padding-left(0.75rem);
    @include padding-right(0.75rem);
    @include media-breakpoint-down(lg) {
        @include padding-left(0rem);
        @include padding-right(0rem);
    }
    @include media-breakpoint-down(sm) {
        padding-left: 0rem;
    }
}

.nav-tabs .nav-link {
    border: 0px;
    border-bottom: 2px solid transparent;
    color: theme-colors(gray);
    @include padding(0.5rem 3rem);
    @include media-breakpoint-down(lg)
    {
padding: 0.5rem calc(0.2rem + 2.4vw);
    font-size: 15px;
    }
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color:theme-colors(secondary);
    color: theme-colors(secondary);
}