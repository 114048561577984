// @import "../../App.scss";

// .size-property {
//   height: calc(99vh - 186px);as
//   background-color: aqua;
// }
.login {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  .row {
    width: 100%;
    .formcard {
      box-shadow: 0 0 10px #554a4a33;

      a.text-primary
      {
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
      form {
        .form-control {
          @include rounded($border-radius);
          font-size: 0.875rem;
          font-weight: normal;
          padding: .5rem 1rem;
          border: 1px solid theme-colors(light-gray);
          background: theme-colors(light-gray);
          &:focus {
            box-shadow: none;
            border: 1px solid transparent;
            outline: none;
          }
          &::placeholder
          {
            color: theme-colors(gray);
            font-weight: 300;
            font-size: 14px;
            font-weight: normal;
          }
         
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px theme-colors(light-gray) inset !important;
            box-shadow: 0 0 0 30px theme-colors(light-gray) inset !important;
            background-color:  theme-colors(light-gray) !important;
            border: 1px solid theme-colors(light-gray);
            font-size: 14px;
            color: #212529;
        }
        .select-tech
        {
          .css-1s2u09g-control, .css-1pahdxg-control
          {
            border-radius: 0.625rem;
            font-size: 0.875rem;
            font-weight: normal;
            padding: 0 0.3rem;
            border: 1px solid theme-colors(light-gray) !important;
            background: theme-colors(light-gray);
            box-shadow: none;
            &:focus
            {
              border: 1px solid theme-colors(light-gray);
            }
          }
          .css-14el2xx-placeholder
          {
            color: theme-colors(gray);
            font-weight: 300;
          }
        }
        .form-check-input {
          &:checked {
            background-color: theme-colors(green);
            border: 1px solid theme-colors(green);
          }
        }
      }
      .footer {
        .btn {
          width: 45%;
        }
      }
    }
  }
}
.my-profile
{
  .row .formcard
  {
    box-shadow: none;
    form .form-control
    {
      &#uemail, &#uname
      {
        cursor: no-drop; 
      }
    }
    .footer {
      .btn {
        width: 35%;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .login {
    .row {
      .formcard {
        box-shadow: 0 0 10px #554a4a33;
        
        form {
          .form-control {
            font-size: 0.8rem;
          }
          .form-check-input {
            &:checked {
              background-color: theme-colors(green);
              border: 1px solid theme-colors(green);
            }
          }
        }
        .footer {
          .btn {
            width: 50%;
          }
          .forgot-btn
          {
            width: 90%;
          }
        }
      }
    }
  }
}
