@import "../../assets/scss/style.scss";

.course-banner {
    @include padding-top(3.75rem);
    @include padding-bottom(1.875rem);
    p{
        line-height: 2;
    }
    .banner-card {
        box-shadow: 0px 0px 15px #00000029;
        @include padding(1.25rem);
        .course-item {
            @include padding-top(1.5rem);
            @include media-breakpoint-down(md) { 
                margin-right: 1rem;
                padding-top: 0.625rem;
            }
        }
        @include media-breakpoint-down(lg)
        {
            box-shadow: none;
            border:  1px solid rgba(0,0,0,.125);  
        }
        
    }
    .btn-blue {
        @include button(theme-colors(primary), theme-colors(white), theme-colors(primary));
        &:hover,&:active {
            @include button(theme-colors(primary), theme-colors(white), theme-colors(primary));
        }
      }
    .btn-blue-hover:hover
    {
        @include button(theme-colors(white), theme-colors(primary), theme-colors(primary));
    }
    .btn-white {
        @include button(theme-colors(white), theme-colors(dark-gray), theme-colors(dark-gray));
        &:hover, &:active {
            @include button(theme-colors(white), theme-colors(dark-gray), theme-colors(dark-gray));
        }
        @include media-breakpoint-down(lg) 
        {
            padding: 0.25rem 1.375rem;
        }
      }
      .sub-course-img {
        object-fit: contain;
            @include media-breakpoint-down(lg)
            {
                height: 150px;
            }
            @include media-breakpoint-down(md) {
                height: 100px;
                width: auto;
                margin: 0 auto;
            }
      }
}

