@import "../../assets/scss/style.scss";
.d-ipad {
  position: relative;
  z-index: 9;
}
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
  border: none !important;
  z-index: 150;
  .header-main {
    padding: 0 1.25rem;
    box-shadow: 0px 3px 6px rgba(102, 102, 102, 0.16);
    background-color: theme-colors(white);
    width: 100%;
    @include media-breakpoint-down(xl)
    {
      padding: 0.25rem 0.25rem;
    }
    .navbar-brand
    {
      display:  inline-block;
      @include media-breakpoint-down(md)
     {
      img{
        max-width: 130px;
        height: auto;
      }
     }
    }
    .mobile-navbar
    {  
      .show
      {
        @include media-breakpoint-down(xl)
        {
          background-color: theme-colors(black);
          padding: 0 0.9375rem;
          height: 100vh;
          position: fixed;
          left: 0px;
          top: 0px;
          width: 100%;
          transition: 0.2s ease-in-out;
          .row {
            background: #fff;
            margin: 15px 0;
            padding: 15px;
            top: 60px;
            position: relative;
            max-height: 80vh;
            overflow-y: auto;
            height: auto;
            .col-megamenu {
              padding: 4px 8px;
              a {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
  .navbar-toggler {
    border: none;
    &:focus {
      box-shadow: none;
  }
    @include media-breakpoint-down(xl)
    {
      z-index: 9999;
      right: 0px;
      position: relative;
      padding: 0;
    }
    @include media-breakpoint-down(md)
    {
    img.closemenu_icon {
        margin-right: -13px;
    }
  
    }
  }
  // .navbar-toggler:focus {
  //   box-shadow: 0 0 4px theme-colors(white);
  // }
  .input-group {
    margin-left: 6.875rem;
    input.search-input {
      @include rounded($border-radius);
      border-top-right-radius: 0;
      font-size: 0.875rem;
      border-bottom-right-radius: 0;
      font-weight: normal;
      padding: 0 1.5625rem;
      border: 1px solid theme-colors(light-gray);
      background: theme-colors(light-gray);
      &:focus {
        box-shadow: none;
        border: 1px solid transparent;
        outline: none;
      }
    }
  }
  .btn-search {
    button {
      @include rounded($border-radius);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: theme-colors(primary);
      border-color: theme-colors(primary);
    }
  }
  .navbar-nav {
    .has-megamenu {
      position: static;
      margin: 0;
      padding: 1.75em 0 2rem; 
      cursor: pointer;
      @include media-breakpoint-down(xl)
      {
        padding: 3.75em 0 2rem;
        height: 100vh;
      }
      @include media-breakpoint-up(xl) {
        &:hover {
          .megamenu {
            visibility: visible;
            opacity: 1;
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            transform: rotateX(0deg);
          }
        }
        .megamenu {
          position: absolute;
          margin-top: 0px;
          max-width: 100%;
          left: -328px;
          box-shadow: 0px 3px 6px rgba(56,56,56,0.5);
          padding: 15px;
          background: #fff;
          top: 100%;
          visibility: hidden;
          opacity: 0;
          box-sizing: border-box;
          -webkit-transition: all 0.4s ease 0s;
          -o-transition: all 0.4s ease 0s;
          transition: all 0.4s ease 0s;
          -webkit-transform: rotateX(90deg);
          -moz-transform: rotateX(90deg);
          -ms-transform: rotateX(90deg);
          transform: rotateX(90deg);
          -webkit-transform-origin: top center;
          -ms-transform-origin: top center;
          transform-origin: top center;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          &.after-login-megamenu {
            left: -305px;
          }
          &.show {
            display: flex;
            padding: 1rem 2.5rem;
            max-width: 1000px;
            left: 15%;
            @include media-breakpoint-down(xl)
            {
              height: 100%;
              position: relative;
              overflow: auto;
              padding: 0rem 1.5rem;
              top: 20px;
              left: 0;
              width: 100%;
              margin-left: 0!important;
              background-color: #ffffff;
              max-width: 100%;
            }
          }
          @include media-breakpoint-down(xl)
            {
              padding: 1rem 1.5rem;
            }
          .col-megamenu
            {
              @include media-breakpoint-down(xl)
              {
                border-bottom: 1px solid #f0f0f0;
                padding: 7px 10px;
              }
              
              &:last-child
              {
                border-bottom: 0;
              }
              a{
                text-decoration: none;
                &:hover
                {
                  text-decoration: underline;
                }
                @include media-breakpoint-up(lg)
              {
                border-bottom: 0px solid #f0f0f0;
                padding: 5px 6px;
                margin: 0 5px;
                display: block;
              }
              &.h6 {
                line-height: 1.5;
                @include media-breakpoint-down(xl)
                {
                  line-height: 2;
                  display: block;
                  margin: 0;
                }
              }
              }
            }
        }
      }
    }
    .nav-item {
      &.profile-btn {
        .nav-link {
          @include button(
            theme-colors(primary),
            theme-colors(white),
            theme-colors(primary)
          );
          min-width: 11.75rem;
          &:hover {
            color: theme-colors(white) !important;
          }
          &.dropdown-toggle:after {
            content: "";
            background-image: url(../../assets/images/arrow-down-white.svg);
            right: 15px;
            -webkit-transition: -webkit-transform 0.3s ease-in-out;
            -ms-transition: -ms-transform 0.3s ease-in-out;
            transition: transform 0.3s ease-in-out;
          }
          &.show {
            &::after {
              transform: rotate(180deg);
              -webkit-transition: -webkit-transform 0.3s ease-in-out;
              -ms-transition: -ms-transform 0.3s ease-in-out;
              transition: transform 0.3s ease-in-out;
            }
          }
        }
        .dropdown-menu.show {
          top: calc(100% - 10px);
          background-color: #005ba0;
          @include rounded($border-radius);
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border: 1px solid #005ba0;
          padding-top: 0;
          min-width: 10.6rem;
          li {
            padding: 0 0.8125rem;
            a {
              padding: 0.65rem 0.375rem;
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);
              color: theme-colors(white);
              font-size: 0.875rem;
              cursor: pointer;
              img {
                margin-right: 0.4375rem;
              }
              &:hover {
                color: #7cceff;
                background: none;
              }
            }
            &:last-child a {
              border-bottom: 0;
            }
          }
        }
      }
      .nav-link {
        font-size: 0.9rem;
        text-transform: capitalize;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: all 0.3s linear;
        cursor: pointer;
        position: relative;
        &.dropdown-toggle:after {
          content: "";
          background-image: url(../../assets/images/arrow-down-black.svg);
          position: absolute;
          border: 0;
          top: 0;
          right: -7px;
          width: 20px;
          height: 100%;
          background-repeat: no-repeat;
          bottom: 0;
          background-position: center;
        }
        &:hover,
        &.dropdown-toggle:after {
          color: theme-colors(primary) !important;
        }
      }
    }
  }
  .no-suggestions {
    color: theme-colors(red);
    position: absolute;
    top: 2.5rem;
    width: calc(100% - 3.3125rem);
    left: 0.625rem;
    padding: 15px 20px;
    background-color: theme-colors(white);
  }
  .suggestions {
    border: 1px solid theme-colors(light-gray);
    position: absolute;
    z-index: 1;
    border-top-width: 0;
    list-style: none;
    margin-top: 2.375rem;
    overflow-y: auto;
    padding-left: 0px;
    width: calc(100% - 3.3125rem);
    left: 0.625rem;
    padding: 15px 20px;
    background-color: theme-colors(white);
    li {
      padding: 5px 0;
      color: theme-colors(dark-gray);
      font-size: theme-fonts(font-14);
      &:hover {
        color: theme-colors(primary);
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .navbar {
      .navbar-toggler {
        padding: 0rem;
      }
      .nav-item {
        .nav-link {
          text-transform: capitalize;
          color: theme-colors(color-lblue);
          background-color: theme-colors(white);
          padding-left: 0rem;
          padding-right: 0rem;
          transition: all 0.3s linear;
          &:hover {
            color: theme-colors(color-lblue);
            outline: none;
            border: none;
            text-decoration: none;
          }
        }
      }
      .has-megamenu .megamenu {
        display: block;
      }
    }
  }
}
.mobile-serach
{
  position: fixed;
  background-color: theme-colors(white);
  top: 0;
  overflow: hidden;
  z-index: 1111;
  padding: 1.875rem;
  @include media-breakpoint-down(xl) {
    padding: 1.875rem 1rem;
  }
  .search-input
    {
      border: 0;
      border-bottom: 1px solid theme-colors(dark-gray);
      border-radius: 0;
      color: theme-colors(dark-gray);
      &:focus
      {
        box-shadow: none;
      }
    }
   
    .suggestions 
    {
      padding: 10px;
      padding-left: 0;
      font-size: 14px;
      list-style: none;
      li
      {
        padding: 3px 0;
        color: theme-colors(dark-gray);
        font-weight: 300;
      }
    }
    .no-suggestions
    {
      @extend .suggestions;
      p
      {
        color: theme-colors(red);
      }
    }
}
// body {
//   font-family: sans-serif;
// }
// input {
//   border: 1px solid #999;
//   padding: 0.5rem;
//   width: 300px;
// }
