@import "../../assets/scss/style.scss";
.training-banner {
    p {
        color: theme-colors(dark);
        line-height: 2;
        &:last-child {
            margin-bottom: 1.5rem;
        }
    }
}
.tutorial-content {
    .accordion-item .accordion-button::after {
        content: none;
    }
}
.disabled-link {
    pointer-events: none;
    opacity: 0.5;
  }