@import "../../assets/scss/style.scss";
.tutorial-banner {
    &.position-sticky {
    // transition: all ease 0.2s;
    width: 100%;
    background: white;
    z-index: 11;
    box-shadow: 0px 3px 6px rgba(102,102,102,0.2%);
    height: 100%;
    .course-tab.nav-pills li
    {
        margin: 0;
    }
    }
   
}

.video-tutorial {
    &:not(.video-fixed) {
        width: 100% !important;
    }
    @include media-breakpoint-up(md) {
        &:not(.video-fixed) {
            min-height: 25rem;
        }
    }
    @include media-breakpoint-up(lg) {
        position: relative;
        bottom: 0px;
        right: 0px;
        // transition: all 0.5s ease-in-out, width 0s ease-in-out;
        &:not(.video-fixed) {
            min-height: 630px;
        }
        &.video-fixed {
            bottom: 30px;
            right: 30px;
            position: fixed;
            top: unset;
            transform: translateY(100%);
            animation: fade-in-up .5s ease forwards;
            border: 2px solid #000000;
    box-shadow: 1px 1px 18px #afafaf;
    z-index: 99;
        }
    }
}
@keyframes fade-in-up {
    0% { opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }

  .sidebar-bg {
    @include media-breakpoint-down(xl) {
    width: 100%;
    height: 99vh;
    background: theme-colors(black);
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 150;
    }
    &.d-none
    {
        z-index: 0;
        & ~ .sidebar-wrapper.collapse-sidebar {
            z-index: 0;
        }
    }
    
    .close-sidebar
    {
        position: absolute;
        right: 1rem;
        top: 1.2rem;
    @include media-breakpoint-down(md)
    {
   
        top: 0.875rem;
    }
    img{
        @include media-breakpoint-down(md)
        {
            width: 1.125rem;
        }
    }
    }
  }
.sidebar-wrapper {
    border-right: 1px solid theme-colors(gray);
    transition: width ease 0.5s;
    position: fixed;
    @include media-breakpoint-down(xl)
    {
        border-right: 0;
        max-height: 100vh;
        position: fixed;
        //width: 90%;
        top: 0;
        z-index: 150;
        left: 0;
        padding-top: 2rem;
        transition: none;
    }

    // overflow: hidden;
    .sidebar-btn {
        position: absolute;
        top: -12px;
        z-index: 11;
        right: -38px;
    }
    .slider-right-btn {
        opacity: 0.5;
    }
    &.collapse-sidebar {
        width: 40px;
        padding: 10px;
        @include media-breakpoint-down(xl)
        {
            width: 100%;
            margin-top: 0;
            top: 0 !important;
            margin: 3em 0 2rem;
        }
        @include media-breakpoint-down(md)
        {
            margin-top: 30px;
        }
        // border: 0px;
        .sidebar  {
            // display: none;
            width: 400px;
            @include media-breakpoint-down(xl)
            {
                width: 100%;
            }
            .accordion-body {
                background: #ffffff;
            }
       
        }
        .collapse-btn {
            opacity: 0.5;
        }
        .slider-right-btn {
            opacity: 1;
        }
    }
    
}
.tab-contents
{
    margin-left: auto;
    @include media-breakpoint-down(xl)
    {
        min-height: auto;
    }
    @media only screen and (max-width: 1025px) { 
        margin-left: 0;
    }
    
}
img.datanotfoundimage{
    @include media-breakpoint-down(xl)
        {
            width: 300px;
    }
}